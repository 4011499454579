import {RoomType} from '../../types/shared';

export type Context = Record<string, unknown>;

export interface PendoContext extends Context {
  environment: string;
  releaseVersion: string;
}

export enum AnalyticsEventType {
  CopyShareLink = 'CopyShareLink',
  ExceededMaxSessionDuration = 'ExceededMaxSessionDuration',
  IdleSession = 'IdleSession',
  LoadingRoomComplete = 'LoadingRoomComplete',
  LoadingStarted = 'LoadingStarted',
  PlatformSelectedPureweb = 'PlatformSelectedPureweb',
  ProvisioningComplete = 'ProvisioningComplete',
  QueueFinished = 'QueueFinished',
  SessionDisconnected = 'SessionDisconnected',
  WalkthroughLoaded = 'WalkthroughLoaded',
  WalkthroughLoaded_Admin = 'WalkthroughLoaded_Admin',
  WalkthroughDisconnected = 'WalkthroughDisconnected',
  WalkthroughDisconnected_Admin = 'WalkthroughDisconnected_Admin',
  WalkthroughSpacePageLoaded_Admin = 'WalkthroughSpacePageLoaded_Admin',
  WalkthroughSpacePageDisconnected_Admin = 'WalkthroughSpacePageDisconnected_Admin',
}

export interface AnyAnalyticsEvent {
  event: string;
  context: Context;
}

export interface PhotorealContext extends Context {
  'social_tables.property.id'?: number;
  'social_tables.property.name': string;
  'social_tables.room.id'?: number;
  'social_tables.room.name': string;
  'social_tables.room.type': RoomType;
  referer?: string;
  sourceSite?: string;
}

export interface CopyShareLinkEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.CopyShareLink;
  context: PhotorealContext & {
    'social_tables.pr3d.copy_link': string;
  };
}

export interface ExceededMaxSessionDurationEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.ExceededMaxSessionDuration;
  context: PhotorealContext & {
    'social_tables.pr3d.max_session_duration': number;
  };
}

export interface IdleSessionEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.IdleSession;
  context: PhotorealContext & {
    'social_tables.pr3d.total_session_duration': number;
  };
}

export interface LoadingContext extends PhotorealContext {
  'social_tables.pr3d.loading_duration': number;
  'social_tables.pr3d.loading_duration_delta': number;
  'social_tables.pr3d.platform': 'pureweb' | 'unset';
  'social_tables.pr3d.pureweb.model.id': string | 'unset';
}

export interface LoadingRoomCompleteEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.LoadingRoomComplete;
  context: LoadingContext;
}

export interface LoadingStartedEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.LoadingStarted;
  context: LoadingContext;
}

export interface PlatformSelectedPurewebEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.PlatformSelectedPureweb;
  context: PhotorealContext & {
    'social_tables.pr3d.pureweb.model.id': string;
  };
}

export interface ProvisioningCompleteEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.ProvisioningComplete;
  context: LoadingContext;
}

export interface QueueFinishedEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.QueueFinished;
  context: LoadingContext;
}

export interface SessionDisconnectedEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.SessionDisconnected;
  context: PhotorealContext & {
    'social_tables.pr3d.session.duration': number;
  };
}

export interface WalkthroughContext extends Context {
  'social_tables.property.id': number;
  'social_tables.property.name': string;
  'social_tables.walkthrough.id': number;
  'social_tables.walkthrough.name': string;
  'social_tables.walkthrough.space_id': number;
  'social_tables.walkthrough.space_name': string;
  'social_tables.walkthrough.matterport_space_id': string;
  referer?: string;
}

export interface WalkthroughLoadedEvent extends AnyAnalyticsEvent {
  event:
    | AnalyticsEventType.WalkthroughLoaded
    | AnalyticsEventType.WalkthroughLoaded_Admin;
  context: WalkthroughContext;
}

export interface WalkthroughDisconnectedEvent extends AnyAnalyticsEvent {
  event:
    | AnalyticsEventType.WalkthroughDisconnected
    | AnalyticsEventType.WalkthroughDisconnected_Admin;
  context: WalkthroughContext & {
    'social_tables.walkthrough.session.duration': number;
  };
}

export interface WalkthroughSpacePageContext extends Context {
  'social_tables.property.id': number;
  'social_tables.property.name': string;
  'social_tables.walkthrough_space.id': number;
  'social_tables.walkthrough_space.name': string;
  'social_tables.walkthrough_space.matterport_space_id': string;
  referer?: string;
}

export interface WalkthroughSpacePageLoadedEvent extends AnyAnalyticsEvent {
  event: AnalyticsEventType.WalkthroughSpacePageLoaded_Admin;
  context: WalkthroughSpacePageContext;
}

export interface WalkthroughSpacePageDisconnectedEvent
  extends AnyAnalyticsEvent {
  event: AnalyticsEventType.WalkthroughSpacePageDisconnected_Admin;
  context: WalkthroughSpacePageContext & {
    'social_tables.walkthrough_space.session.duration': number;
  };
}
