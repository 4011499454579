import {ReactNode, JSX} from 'react';
import {type} from 'ramda';
import styles from './ErrorPage.module.css';

type Props = {
  children?: ReactNode;
};

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please contact support for help.';

export const ErrorPage = ({children}: Readonly<Props>): JSX.Element => (
  <div className={styles.overlay}>
    {type(children) === 'String' || !children ? (
      <p className={styles.message}>{children ?? DEFAULT_ERROR_MESSAGE}</p>
    ) : (
      <div className={styles.message}>{children}</div>
    )}
  </div>
);
