import {StreamingPlatformState, MessageReducer} from '../../types/shared';
import {SetStreamingAction} from '../../types/client-actions';

export const setStreaming: MessageReducer<
  StreamingPlatformState,
  SetStreamingAction
> = (state, {payload: {streaming}}): StreamingPlatformState => ({
  ...state,
  streaming,
});
