import {forwardRef, useState, useCallback, useEffect, JSX} from 'react';
import {
  LoadingPhase,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {DEFAULT_LOADING_IMAGE} from '../../../lib/constants';
import IndeterminateLoader from '../../IndeterminateLoader';
import {PlayButton} from './PlayButton';
import styles from './LoadingOverlay.module.css';

type Props = {
  autoplayAllowed: boolean;
  bookableRoomName: string;
  loadingImage?: string | null;
  onEnterRoom: () => void;
  propertyName: string;
};

type Ref = HTMLDivElement;

const PhaseMessage: Record<LoadingPhase, string> = {
  [LoadingPhase.NotStarted]: 'Your 3D experience is about to begin',
  [LoadingPhase.Queued]: 'Your 3D experience is loading',
  [LoadingPhase.Provisioning]: 'Your 3D experience is loading',
  [LoadingPhase.RoomLoading]: 'Your 3D experience is launching',
  [LoadingPhase.Ended]: '',
} as const;

export const LoadingOverlay = forwardRef<Ref, Props>(
  (
    {
      autoplayAllowed,
      bookableRoomName,
      loadingImage,
      onEnterRoom,
      propertyName,
    }: Props,
    ref
  ): JSX.Element => {
    const {
      state: {loading},
    } = useStreamingPlatform();
    const {phase} = loading;
    const [showLoadingBar, setShowLoadingBar] = useState(false);
    const handleOnPlayButtonExit = useCallback(
      () => setShowLoadingBar(true),
      []
    );

    const loadingMessage = showLoadingBar ? PhaseMessage[phase] : '';
    const backgroundImage = loadingImage || DEFAULT_LOADING_IMAGE;

    // Autoplay will always be false on first load so we must react to it.
    useEffect(() => {
      setShowLoadingBar(autoplayAllowed);
    }, [autoplayAllowed]);

    return (
      <div
        ref={ref}
        className={styles.overlay}
        style={{backgroundImage: `url(${backgroundImage})`}}
      >
        <div className={styles.controls}>
          <div className={styles.propertyInfo}>
            <h2 className={styles.propertyTitle}>{propertyName}</h2>
            <h1 className={styles.bookableRoomTitle}>{bookableRoomName}</h1>
          </div>
          {showLoadingBar ? (
            <IndeterminateLoader />
          ) : (
            <PlayButton
              className={styles.playButton}
              onClick={onEnterRoom}
              onExited={handleOnPlayButtonExit}
            />
          )}
          <p className={styles.message}>{loadingMessage}&nbsp;</p>
        </div>
      </div>
    );
  }
);

LoadingOverlay.displayName = 'LoadingOverlay';
