import {AnalyticsEventType, PlatformSelectedPurewebEvent} from '../types';

export function platformSelectedPurewebEvent(
  context: PlatformSelectedPurewebEvent['context']
): PlatformSelectedPurewebEvent {
  return {
    event: AnalyticsEventType.PlatformSelectedPureweb,
    context,
  };
}
