import {UpdateProvisioningProgressAction} from '../types/client-actions';
import {ClientActionType} from '../types/shared';

export const updateProvisioningProgressAction = (
  progress: number
): UpdateProvisioningProgressAction => ({
  type: ClientActionType.UpdateProvisioningProgress,
  payload: {
    progress,
  },
});
