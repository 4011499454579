import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useCanAutoplay} from './use-can-autoplay';

export function useAutoplay(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const autoplayAllowed = useCanAutoplay();
  const [beginPlay, setBeginPlay] = useState(false);

  useEffect(() => {
    if (!autoplayAllowed) {
      return;
    }

    setBeginPlay(autoplayAllowed);
  }, [autoplayAllowed]);

  return [beginPlay, setBeginPlay];
}
