export const detectAutoplay = async (): Promise<boolean> => {
  const video = document.getElementById('autoplay-video') as HTMLMediaElement;

  if (!video) {
    return false;
  }

  try {
    await video.play();
    video.pause();
    return true;
  } catch (err) {
    return false;
  }
};
