import type EventEmitter from 'eventemitter2';
import {identity} from 'ramda';
import {logger} from '../../../lib/datadog';
import {
  AnyPlatformAction,
  ClientActionType,
  GameMessageType,
  StreamingPlatformState,
} from './types/shared';
import * as reducers from './reducers';

const noopReducer = identity;

const messageReducers = {
  [GameMessageType.AppReadyToReceive]: reducers.appReadyToReceive,
  [GameMessageType.EngineError]: reducers.engineError,
  [GameMessageType.LoadingError]: reducers.loadingError,
  [GameMessageType.RoomLoadingComplete]: reducers.roomLoadingComplete,
  [GameMessageType.RoomLoadingInProgress]: reducers.roomLoadingInProgress,
  [GameMessageType.AnalyticsEvent]: noopReducer,
  [GameMessageType.CopyShareLink]: noopReducer,
  [GameMessageType.OpenVenueMapper]: noopReducer,
  [GameMessageType.WLRFPButtonClicked]: noopReducer,
  [ClientActionType.AppNotReady]: reducers.appNotReady,
  [ClientActionType.QueueStarted]: reducers.queueStarted,
  [ClientActionType.SessionTimeout]: reducers.sessionTimeout,
  [ClientActionType.SetStreaming]: reducers.setStreaming,
  [ClientActionType.SetStreamingPlatformLoaded]:
    reducers.setStreamingPlatformLoaded,
  [ClientActionType.StartLoading]: reducers.startLoading,
  [ClientActionType.UpdateProvisioningProgress]:
    reducers.updateProvisioningProgress,
} as const;

export const createReducer = (messageEmitter: EventEmitter) =>
  function reducer(
    state: StreamingPlatformState,
    message: AnyPlatformAction
  ): StreamingPlatformState {
    const reduce = messageReducers[message.type];
    logger.debug('Platform state event', {message});

    messageEmitter.emit(message.type, message.payload);

    if (!reduce) {
      logger.debug('Failed to match the message to a reducer', {message});
      return state;
    }

    // Using never as we don't care what the message type is.
    return reduce(state, message as never);
  };
