import {useEffect} from 'react';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {
  AnalyticsEventMessage,
  GameMessageType,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {
  AnyAnalyticsEvent,
  PhotorealContext,
  trackEvent,
} from '../../../lib/analytics';

export function useAnalyticsEventEffect(): void {
  const {messageEmitter} = useStreamingPlatform();
  const {propertyId, propertyName, roomId, roomName, roomType} =
    useRoomMetadataFlags();

  useEffect(() => {
    const sharedContext: PhotorealContext = {
      'social_tables.property.id': propertyId,
      'social_tables.property.name': propertyName,
      'social_tables.room.id': roomId,
      'social_tables.room.name': roomName,
      'social_tables.room.type': roomType,
    };

    const handler = (message: AnalyticsEventMessage['payload']) => {
      const event: AnyAnalyticsEvent = {
        event: message.Name,
        context: {...sharedContext, ...message.MetaData},
      };

      trackEvent(event);
    };

    messageEmitter.on(GameMessageType.AnalyticsEvent, handler);

    return () => {
      messageEmitter.off(GameMessageType.AnalyticsEvent, handler);
    };
  }, [messageEmitter, propertyId, propertyName, roomId, roomName, roomType]);
}
