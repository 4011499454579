import {createContext, useContext} from 'react';
import {noop} from 'ts-essentials';
import {useImmer} from 'use-immer';
import {RoomType} from '../../types/shared';

export type RoomMetadataState = {
  roomModel?: string;
  propertyId?: number;
  propertyName: string;
  roomId?: number;
  roomName: string;
  roomType: RoomType;
  loadingImageUrl?: string | null;
};

export type SetRoomMetadataState = (state: RoomMetadataState) => void;

export type RoomMetadataProviderProps = {
  roomMetadataProviderValue: RoomMetadataState;
};

export const DEFAULT_STATE: Readonly<RoomMetadataState> = {
  roomModel: '',
  propertyName: '',
  roomName: '',
  roomType: RoomType.Photoreal,
};

export const DEFAULT_CONTEXT: Readonly<RoomMetadataState> = DEFAULT_STATE;

const RoomMetadataContext = createContext<RoomMetadataState>(DEFAULT_CONTEXT);
const RoomMetadataWriteContext = createContext<SetRoomMetadataState>(noop);
RoomMetadataContext.displayName = 'RoomMetadataContext';
RoomMetadataWriteContext.displayName = 'RoomMetadataWriteContext';

export const useRoomMetadata = (): RoomMetadataState =>
  useContext(RoomMetadataContext);

export const useSetRoomMetadata = () => useContext(RoomMetadataWriteContext);

export function withRoomMetadataProvider<T extends RoomMetadataProviderProps>(
  Component: React.ComponentType<T>
): React.FunctionComponent<T> {
  const RoomMetadataProvider = (
    props: T
  ): React.FunctionComponentElement<T> => {
    const [state, setState] = useImmer(props.roomMetadataProviderValue);

    return (
      <RoomMetadataContext.Provider value={state}>
        <RoomMetadataWriteContext.Provider value={setState}>
          <Component {...props} />
        </RoomMetadataWriteContext.Provider>
      </RoomMetadataContext.Provider>
    );
  };

  RoomMetadataProvider.displayName = 'RoomMetadataProvider';
  return RoomMetadataProvider;
}

export const useRoomMetadataFlags = (): RoomMetadataState =>
  useContext(RoomMetadataContext);
