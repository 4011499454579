import {useEffect, useState} from 'react';
import {detectAutoplay} from '../../lib/detect-autoplay';

export function useCanAutoplay(): boolean {
  const [canAutoplay, setCanAutoplay] = useState<boolean>(false);
  useEffect(() => {
    async function check() {
      const canPlay = await detectAutoplay();
      setCanAutoplay(canPlay);
    }
    check();
  }, [setCanAutoplay]);

  return !!canAutoplay;
}
