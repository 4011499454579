import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {LoadingErrorMessage} from '../../types/game-messages';

export const loadingError: MessageReducer<
  StreamingPlatformState,
  LoadingErrorMessage
> = (state, {payload: {Error: errorMessage}}): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    errorMessage,
    phase: LoadingPhase.Ended,
    progress: 0,
  },
});
