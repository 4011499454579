import {StreamingPlatformState, MessageReducer} from '../../types/shared';
import {AppReadyToReceiveMessage} from '../../types/game-messages';

export const appReadyToReceive: MessageReducer<
  StreamingPlatformState,
  AppReadyToReceiveMessage
> = (state: StreamingPlatformState): StreamingPlatformState => ({
  ...state,
  gameReady: true,
});
