// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PendoProperties = Record<string, any>;

type Pendo = {
  track: (event: string, properties: PendoProperties) => void;
};

export type GTMDataLayer = Record<string, unknown>;

export declare interface WindowExtended extends Window {
  dataLayer?: GTMDataLayer[];
  pendo?: Pendo;
}

export const getWindow = (): WindowExtended =>
  window as WindowExtended & typeof globalThis;

export const getDataLayer = (): GTMDataLayer[] | undefined =>
  getWindow().dataLayer;

export const getPendo = (): Pendo | undefined => getWindow().pendo;
