import {useEffect} from 'react';
import {allEnv} from 'next-runtime-env';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {
  GameMessageType,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {logger} from '../../../lib/datadog';

function getWhitelabelRFPUrl(
  propertyId: number,
  locale?: string,
  primaryColor?: string
) {
  const publicRuntimeConfig = allEnv<IPublicEnv>();
  const url = new URL(
    '/rfps/create',
    publicRuntimeConfig.NEXT_PUBLIC_WL_RFP_URL
  );
  url.search = new URLSearchParams({
    uid: propertyId.toString(),
    accessKey: publicRuntimeConfig.NEXT_PUBLIC_WL_RFP_ACCESS_TOKEN,
    isInWindow: 'true',
    display: 'window',
    ...(locale ? {locale} : {}),
    ...(primaryColor ? {primaryColor} : {}),
  }).toString();
  return url.href;
}

/**
 * useWhiteLabelRfpClickEffect registers a listener for opening and integrating with Whitelabel RFP when requested by the game
 */
export function useWhiteLabelRfpClickEffect(
  showWhitelabelRFP: boolean | undefined
): void {
  const {messageEmitter} = useStreamingPlatform();
  const {propertyId, roomName} = useRoomMetadataFlags();

  useEffect(() => {
    if (!showWhitelabelRFP) {
      return;
    }
    const whitelabelRFPUrl = propertyId && getWhitelabelRFPUrl(propertyId);

    const wlRFPButtonOnClickhandler = () => {
      if (!whitelabelRFPUrl) {
        logger.error('Whitelabel RFP URL not found');
        return;
      }
      let openedWindow: Window | null = null;
      const handleOpenedReady = (event: MessageEvent<unknown>) => {
        if (event.data === 'openedReady' && openedWindow) {
          openedWindow.postMessage(
            {
              token: 'a523b952-b5f5-4726-809a-a656949c8571', // WL RFP token to identify the additional comment message
              additionalComments: `${roomName}`,
            },
            whitelabelRFPUrl
          );

          window.removeEventListener('message', handleOpenedReady);
        }
      };

      // start listening before we open the window so we don't miss the `openedReady` message
      window.addEventListener('message', handleOpenedReady);
      openedWindow = window.open(whitelabelRFPUrl, '_blank');
    };

    const handler = () => {
      try {
        wlRFPButtonOnClickhandler();
      } catch (error) {
        logger.error('unable to open Whitelabel RFP : ', {error});
      }
    };
    messageEmitter.on(GameMessageType.WLRFPButtonClicked, handler);
    return () => {
      messageEmitter.off(GameMessageType.WLRFPButtonClicked, handler);
    };
  }, [messageEmitter, roomName, propertyId]);
}
