import {allEnv} from 'next-runtime-env';
import {useEffect} from 'react';
import {
  GameMessageType,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {logger} from '../../../lib/datadog';

type Options = {
  diagramId?: string;
};

const {NEXT_PUBLIC_VENUE_MAPPER_URL} = allEnv<IPublicEnv>();

/**
 * useOpenDiagramEffect registers a listener for opening diagrams when requested by the game.
 * This uses a legacy event name, 'OpenVenueMapper'
 * TODO: update event name to match expected outcome
 */
export function useOpenDiagramEffect({diagramId}: Options): void {
  const {messageEmitter} = useStreamingPlatform();
  useEffect(() => {
    const handler = () => {
      if (!diagramId) {
        logger.error('No diagram ID is available.');
        return;
      }

      const url = new URL(NEXT_PUBLIC_VENUE_MAPPER_URL);
      url.pathname = `diagram/${diagramId}`;

      window.open(url.toString(), 'venue-mapper');
    };

    messageEmitter.on(GameMessageType.OpenVenueMapper, handler);

    return () => {
      messageEmitter.off(GameMessageType.OpenVenueMapper, handler);
    };
  }, [messageEmitter, diagramId]);
}
