import {createContext, useContext} from 'react';
import {FeatureFlags, DEFAULT_FLAGS} from '../../lib/flag-fetcher';

export type FeatureFlagsContext = FeatureFlags;

export const DEFAULT_CONTEXT: Readonly<FeatureFlagsContext> = DEFAULT_FLAGS;

export const Context = createContext(DEFAULT_CONTEXT);
Context.displayName = 'FeatureFlagsContext';

export type FeatureFlagsProviderProps = {
  featureFlagsProviderValue: FeatureFlagsContext;
};

export function withFeatureFlagsProvider<T extends FeatureFlagsProviderProps>(
  Component: React.ComponentType<T>
): React.FunctionComponent<T> {
  const FeatureFlagsProvider = (
    props: T
  ): React.FunctionComponentElement<T> => (
    <Context.Provider value={props.featureFlagsProviderValue}>
      <Component {...props} />
    </Context.Provider>
  );

  FeatureFlagsProvider.displayName = 'FeatureFlagsProvider';
  return FeatureFlagsProvider;
}

export const useFeatureFlags = (): FeatureFlagsContext => useContext(Context);
