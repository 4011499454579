import {v4 as guid} from 'uuid';
import {useCallback, useEffect, useState} from 'react';
import {logger} from '../../../lib/datadog';

// This should be unique to each running process (tab) for identification.
export const BROWSER_SESSION_GUID = guid();

// SessionStorage variable name.
export const ACTIVE_STREAMING_SESSION_KEY = 'socialtables.pr3d.streaming';

/**
 * Communicates with other local tabs to determine whether this instance or
 * another should be allowed to stream the application. By default this will
 * have control set to true as each new window should have control.
 *
 * - hasControl: `true` if this instance currently has control and should be
 *   allowed to stream
 * - wrestControl: tell other instances they no longer have control and sets-
 *   hasControl to true
 */
export function useStreamingSessionControl(): [boolean, () => void] {
  const [hasControl, setHasControl] = useState(false);

  // Update which tab has control.
  useEffect(() => {
    logger.debug('BROWSER_SESSION_GUID', {BROWSER_SESSION_GUID});
    if (hasControl) {
      window.localStorage.setItem(
        ACTIVE_STREAMING_SESSION_KEY,
        BROWSER_SESSION_GUID
      );
    }
  }, [hasControl]);

  // Subscribe to changes in SessionStorage for ACTIVE_STREAMING_SESSION_KEY.
  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key !== ACTIVE_STREAMING_SESSION_KEY) {
        return;
      }

      // This will always be false since our own updates don't trigger events.
      const shouldHaveControl = event.newValue === BROWSER_SESSION_GUID;
      setHasControl(shouldHaveControl);
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, []);

  const wrestControl = useCallback(() => setHasControl(true), []);

  return [hasControl, wrestControl];
}
