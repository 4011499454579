import {SetStreamingPlatformLoadedAction} from '../types/client-actions';
import {ClientActionType, StreamingPlatformState} from '../types/shared';

type Options = {
  isLoaded: boolean;
  platform: StreamingPlatformState['platform'];
};

export const setStreamingPlatformLoadedAction = ({
  isLoaded,
  platform,
}: Options): SetStreamingPlatformLoadedAction => ({
  type: ClientActionType.SetStreamingPlatformLoaded,
  payload: {
    isLoaded,
    platform,
  },
});
