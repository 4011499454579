import {AnalyticsEventType, CopyShareLinkEvent} from '../types';

export function copyShareLinkEvent(
  context: CopyShareLinkEvent['context']
): CopyShareLinkEvent {
  return {
    event: AnalyticsEventType.CopyShareLink,
    context,
  };
}
