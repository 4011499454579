import {ReactNode, MouseEventHandler, useRef} from 'react';
import cc from 'classcat';
import {BubbleTransition} from '../BubbleTransition';
import styles from '../bubble.module.scss';

type Props = {
  onExited?: () => void;
  children?: ReactNode;
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const BubbleButton = ({
  children,
  onExited,
  className,
  onClick,
}: Readonly<Props>) => {
  const nodeRef = useRef<HTMLButtonElement>(null);

  return (
    <BubbleTransition nodeRef={nodeRef} onExited={onExited}>
      <button
        onClick={onClick}
        ref={nodeRef}
        className={cc([styles.button, className])}
      >
        <span className={styles.label}>{children}</span>
      </button>
    </BubbleTransition>
  );
};
