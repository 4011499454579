import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {QueueStartedAction} from '../../types/client-actions';
import {calculatePhaseProgress} from '../../../../../lib/loading-progress-calculator';

export const queueStarted: MessageReducer<
  StreamingPlatformState,
  QueueStartedAction
> = (state: StreamingPlatformState): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    phase: LoadingPhase.Queued,
    progress: calculatePhaseProgress(
      LoadingPhase.Queued,
      0,
      state.loading.type
    ),
  },
});
