import {useEffect, useRef} from 'react';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {
  LoadingPhase,
  StreamingPlatformType,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {LoadingContext, trackEvent} from '../../../lib/analytics';
import {loadingRoomCompleteEvent} from '../../../lib/analytics/events/loading-room-complete';
import {loadingStartedEvent} from '../../../lib/analytics/events/loading-started';
import {provisioningCompleteEvent} from '../../../lib/analytics/events/provisioning-complete';
import {queueFinishedEvent} from '../../../lib/analytics/events/queue-finished';

export type Options = {
  onLoadStart?: () => void;
  referrer?: string;
};

export function useLoadingPhaseEffect({
  onLoadStart,
  referrer,
}: Options = {}): void {
  const {propertyId, propertyName, roomId, roomName, roomType} =
    useRoomMetadataFlags();
  const {
    state: {loading, platform: initialPlatform},
  } = useStreamingPlatform();
  const {phase, startedAt} = loading;
  const phaseRef = useRef(phase);
  const durationRef = useRef(0);

  useEffect(() => {
    const previousPhase = phaseRef.current;
    phaseRef.current = phase;
    const previousDuration = durationRef.current;

    // Only check when phase has transitioned.
    if (previousPhase === phase) {
      return;
    }

    const duration = startedAt ? Date.now() - startedAt.getTime() : 0;
    durationRef.current = duration;
    const context: LoadingContext = {
      'social_tables.pr3d.loading_duration': duration,
      'social_tables.pr3d.loading_duration_delta': duration - previousDuration,
      'social_tables.pr3d.platform': initialPlatform.type,
      'social_tables.pr3d.pureweb.model.id':
        initialPlatform.type === StreamingPlatformType.PUREWEB
          ? initialPlatform.id
          : 'unset',
      'social_tables.property.id': propertyId,
      'social_tables.property.name': propertyName,
      'social_tables.room.id': roomId,
      'social_tables.room.name': roomName,
      'social_tables.room.type': roomType,
      referer: referrer,
    };

    if (previousPhase === LoadingPhase.NotStarted) {
      onLoadStart?.();
      trackEvent(loadingStartedEvent(context));
    }

    if (previousPhase === LoadingPhase.Queued) {
      trackEvent(queueFinishedEvent(context));
    }

    if (previousPhase === LoadingPhase.Provisioning) {
      trackEvent(provisioningCompleteEvent(context));
    }

    if (phase === LoadingPhase.Ended) {
      trackEvent(loadingRoomCompleteEvent(context));
    }
  }, [
    initialPlatform,
    onLoadStart,
    phase,
    propertyId,
    propertyName,
    roomId,
    roomName,
    roomType,
    startedAt,
    referrer,
  ]);
}
