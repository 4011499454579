import {useState, Fragment} from 'react';
import {BubbleButton} from '../../../bubble/BubbleButton';

type PlayButtonProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onExited?: () => void;
};

export const PlayButton = ({onClick, onExited, className}: PlayButtonProps) => {
  const [showPlayButton, setShowPlayButton] = useState(true);
  const handleOnPlayButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.currentTarget.blur();
    setShowPlayButton(false);
    onClick?.(event);
  };

  if (showPlayButton) {
    return (
      <BubbleButton
        onExited={onExited}
        className={className}
        onClick={handleOnPlayButtonClick}
      >
        Enter Room
      </BubbleButton>
    );
  }

  return <Fragment />;
};
