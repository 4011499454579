import {StreamingPlatformState, MessageReducer} from '../../types/shared';
import {StartLoadingAction} from '../../types/client-actions';

export const startLoading: MessageReducer<
  StreamingPlatformState,
  StartLoadingAction
> = (state: StreamingPlatformState): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    startedAt: new Date(),
  },
});
