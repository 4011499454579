import {AnalyticsEventType, SessionDisconnectedEvent} from '../types';

export function sessionDisconnectedEvent(
  context: SessionDisconnectedEvent['context']
): SessionDisconnectedEvent {
  return {
    event: AnalyticsEventType.SessionDisconnected,
    context,
  };
}
