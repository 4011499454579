import {JSX} from 'react';
import styles from './SessionTimeoutPage.module.css';

type Props = {
  bookableRoomName: string;
  propertyName: string;
  backgroundImage?: string | null;
};

export function SessionTimeoutPage(props: Readonly<Props>): JSX.Element {
  const {backgroundImage, propertyName, bookableRoomName} = props;
  return (
    <div
      className={styles.overlay}
      style={{backgroundImage: `url(${backgroundImage})`}}
    >
      <div className={styles.propertyInfo}>
        <h2 className={styles.propertyTitle}>{propertyName}</h2>
        <h1 className={styles.bookableRoomTitle}>{bookableRoomName}</h1>
        <h3 className={styles.message}>Your session has timed out.</h3>
      </div>
    </div>
  );
}
