import {useEffect} from 'react';
import {logger} from '../../../lib/datadog';

type Options = {
  runtimeError?: string | null;
  loadingError?: string | null;
};

export function useErrorLoggingEffect({
  runtimeError,
  loadingError,
}: Options): void {
  useEffect(() => {
    if (loadingError) {
      logger.warn('Error loading room', {error: loadingError});
    }
  }, [loadingError]);

  useEffect(() => {
    if (runtimeError) {
      logger.error('Game error', {error: runtimeError});
    }
  }, [runtimeError]);
}
