import {AnalyticsEventType, ProvisioningCompleteEvent} from '../types';

export function provisioningCompleteEvent(
  context: ProvisioningCompleteEvent['context']
): ProvisioningCompleteEvent {
  return {
    event: AnalyticsEventType.ProvisioningComplete,
    context,
  };
}
