import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {RoomLoadingCompleteMessage} from '../../types/game-messages';
import {calculatePhaseProgress} from '../../../../../lib/loading-progress-calculator';

export const roomLoadingComplete: MessageReducer<
  StreamingPlatformState,
  RoomLoadingCompleteMessage
> = (state): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    phase: LoadingPhase.Ended,
    progress: calculatePhaseProgress(LoadingPhase.Ended, 0, state.loading.type),
    endedAt: new Date(),
  },
});
