import {AnalyticsEventType, ExceededMaxSessionDurationEvent} from '../types';

export function exceededMaxSessionDurationEvent(
  context: ExceededMaxSessionDurationEvent['context']
): ExceededMaxSessionDurationEvent {
  return {
    event: AnalyticsEventType.ExceededMaxSessionDuration,
    context,
  };
}
