import {calculatePhaseProgress} from '../../../../../lib/loading-progress-calculator';
import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {UpdateProvisioningProgressAction} from '../../types/client-actions';

export const updateProvisioningProgress: MessageReducer<
  StreamingPlatformState,
  UpdateProvisioningProgressAction
> = (state, {payload: {progress}}): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    phase: LoadingPhase.Provisioning,
    progress: calculatePhaseProgress(
      LoadingPhase.Provisioning,
      progress,
      state.loading.type
    ),
  },
});
