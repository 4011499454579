import {ReactNode, MouseEventHandler, RefObject} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import styles from '../bubble.module.scss';

type Props = {
  children: ReactNode;
  onExited?: () => void;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  nodeRef?: RefObject<HTMLButtonElement>;
};

export const BubbleTransition = ({
  children,
  onExited,
  nodeRef,
}: Readonly<Props>) => {
  return (
    <TransitionGroup className={styles.wrapper}>
      <CSSTransition
        timeout={1500}
        nodeRef={nodeRef}
        classNames={{
          exitActive: styles.exitActive,
        }}
        onExited={onExited}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};
