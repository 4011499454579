import {AnalyticsEventType, LoadingStartedEvent} from '../types';

export function loadingStartedEvent(
  context: LoadingStartedEvent['context']
): LoadingStartedEvent {
  return {
    event: AnalyticsEventType.LoadingStarted,
    context,
  };
}
