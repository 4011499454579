import {allEnv} from 'next-runtime-env';
import {useEffect, useState} from 'react';
import {useFeatureFlags} from '../../../helpers/context/feature-flags-context';
import {useQueryParams} from '../../../helpers/hooks/use-query-params';
import {logger} from '../../../lib/datadog';
import {STREAMING_PLATFORM_FLAG_NAME} from '../../../lib/flag-fetcher';

const {NEXT_PUBLIC_STREAMING_PLATFORM} = allEnv<IPublicEnv>();

export type StreamingPlatform = 'pureweb';

const StreamingPlatforms: Record<string, StreamingPlatform> = {
  pureweb: 'pureweb',
};

const SupportedStreamingPlatforms = Object.keys(
  StreamingPlatforms
) as StreamingPlatform[];

type QueryParams = {
  platform?: StreamingPlatform;
};

export function useSelectStreamingPlatform(): StreamingPlatform {
  const {platform} = useQueryParams<QueryParams>();
  const flags = useFeatureFlags();
  const [streamingPlatform, setStreamingPlatform] = useState<StreamingPlatform>(
    ((platform ?? flags[STREAMING_PLATFORM_FLAG_NAME]) ||
      NEXT_PUBLIC_STREAMING_PLATFORM) as StreamingPlatform
  );

  useEffect(() => {
    if (SupportedStreamingPlatforms.includes(streamingPlatform)) {
      logger.debug(`Using streaming platform ${streamingPlatform}`);
      return;
    }

    setStreamingPlatform(StreamingPlatforms.pureweb);

    logger.error(
      'Failed to find streaming platform, using pureweb as default',
      {streamingPlatform}
    );
  }, [streamingPlatform]);

  return streamingPlatform;
}
