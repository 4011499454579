import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  ReactNode,
  JSX,
} from 'react';
import EventEmitter from 'eventemitter2';
import {mergeDeepRight} from 'ramda';
import {RoomType} from '../../../types/shared';
import {
  AnyPlatformAction,
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
  StreamingPlatformType,
} from './types/shared';
import {createReducer} from './reducer';

export * from './actions';
export * from './types/game-messages';
export * from './types/shared';

export type ContextValue = Readonly<{
  state: StreamingPlatformState;
  dispatch: Dispatch<AnyPlatformAction>;
  messageEmitter: EventEmitter;
}>;

const DEFAULT_GAME_STATE: StreamingPlatformState = {
  copyLink: {},
  gameReady: false,
  loading: {
    type: RoomType.Photoreal,
    phase: LoadingPhase.NotStarted,
    endedAt: null,
    errorMessage: null,
    progress: 0.0,
    startedAt: null,
  },
  platform: {
    type: StreamingPlatformType.UNSET,
    id: 'unset',
  },
  streaming: false,
  streamingPlatformLoaded: false,
  runtimeErrorMessage: null,
  sessionTimedOut: null,
} as const;

const messageEmitter = new EventEmitter();

export const DEFAULT_CONTEXT: ContextValue = {
  state: DEFAULT_GAME_STATE,
  dispatch: (() => undefined) as never,
  messageEmitter,
} as const;

export const Context = createContext<ContextValue>(DEFAULT_CONTEXT);
Context.displayName = 'StreamingPlatformContext';

export interface Props {
  loadingType: RoomType;
  children: ReactNode;
}

export function Provider({
  loadingType,
  children,
}: Readonly<Props>): JSX.Element {
  const [state, dispatch] = useReducer<MessageReducer<StreamingPlatformState>>(
    createReducer(messageEmitter),
    mergeDeepRight(DEFAULT_GAME_STATE, {loading: {type: loadingType}})
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{dispatch, state, messageEmitter}}>
      {children}
    </Context.Provider>
  );
}

export const useStreamingPlatform = (): Readonly<ContextValue> =>
  useContext(Context);
