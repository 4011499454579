import {AnalyticsEventType, LoadingRoomCompleteEvent} from '../types';

export function loadingRoomCompleteEvent(
  context: LoadingRoomCompleteEvent['context']
): LoadingRoomCompleteEvent {
  return {
    event: AnalyticsEventType.LoadingRoomComplete,
    context,
  };
}
