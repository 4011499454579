import {getDataLayer} from './globals';
import {isServerSide} from './is-server-side';

export const IDENTIFY_EVENT = 'user_properties_loaded';
export const PRODUCT_NAME = 'st_visualizer_frontend';

export function initializeUserProperties(user?: StUser): void {
  if (isServerSide()) {
    return;
  }

  const dataLayer = getDataLayer();

  if (!dataLayer) {
    return;
  }

  const team = user?.team;
  const userIdentity: Record<string, unknown> = {
    productName: PRODUCT_NAME,
  };

  if (user) {
    Object.assign(userIdentity, {
      userEmail: user.email,
      userId: user.id,
      userFirstName: user.addressable_name,
      userLastName: user.sortable_name,
      userAdmin: !!user.legacy_is_admin,
      userTeamRole: user.legacy_team_role,
      teamClientType: user.legacy_client_type,
      teamPlanType: user.legacy_plan_type,
      userCreatedAt: user.created_at ? new Date(user.created_at) : null,
      userTrial: user.legacy_trial,
      userJobTitle: user.job_title,
      legacyUserId: user.legacy_id,
      salesforceAccountId: user.sf_account_id,
    });
  }

  if (team) {
    Object.assign(userIdentity, {
      userTeamId: team.id,
      teamName: team.name,
      teamParentTeamId: team.parent_team_id,
      legacyTeamId: team.legacy_id,
    });
  }

  // this is the trigger that invokes GTM (and Pendo user identity)
  dataLayer.push(userIdentity);
  dataLayer.push({event: IDENTIFY_EVENT});
}
