import {clamp, curry} from 'ramda';
import {LoadingPhase} from '../helpers/context/streaming-platform-state/types/shared';
import {RoomType} from '../types/shared';

type LoadingWeights = Record<LoadingPhase, number>;

// Values are minimum start progress for each phase of loading.
const PROGRESS_WEIGHT: LoadingWeights = {
  [LoadingPhase.NotStarted]: 0.0,
  [LoadingPhase.Queued]: 0.15,
  [LoadingPhase.Provisioning]: 0.2,
  [LoadingPhase.RoomLoading]: 0.5,
  [LoadingPhase.Ended]: 1, // Loading complete so starting progress is 100%
} as const;

const LOADING_MULTIPLIER: Record<RoomType, number> = {
  [RoomType.Photoreal]: 1,
  [RoomType.Standard]: 1.9,
} as const;

export const calculatePhaseProgress = curry(
  (phase: LoadingPhase, progress: number, loadingType: RoomType) => {
    if (phase === LoadingPhase.Ended) {
      return 1;
    }

    const nextPhase: LoadingPhase = phase + 1;
    const range = PROGRESS_WEIGHT[nextPhase] - PROGRESS_WEIGHT[phase];
    const weight = PROGRESS_WEIGHT[phase] * LOADING_MULTIPLIER[loadingType];

    return clamp(0, 1, weight + range * progress);
  }
);
