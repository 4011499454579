import {AnalyticsEventType, IdleSessionEvent} from '../types';

export function idleSessionEvent(
  context: IdleSessionEvent['context']
): IdleSessionEvent {
  return {
    event: AnalyticsEventType.IdleSession,
    context,
  };
}
