import type {ParsedUrlQuery} from 'querystring';
import {useSearchParams} from 'next/navigation';

export function useQueryParams<
  QueryParams extends ParsedUrlQuery
>(): QueryParams {
  const query = useSearchParams();
  if (query) {
    return Object.fromEntries(query) as QueryParams;
  }

  return {} as QueryParams;
}
