import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {EngineErrorMessage} from '../../types/game-messages';

export const engineError: MessageReducer<
  StreamingPlatformState,
  EngineErrorMessage
> = (state, {payload: {Message}}): StreamingPlatformState => {
  const loading = state.loading;

  if (loading.phase !== LoadingPhase.Ended) {
    loading.endedAt = new Date();
    loading.phase = LoadingPhase.Ended;
  }

  return {
    ...state,
    runtimeErrorMessage: Message,
    loading,
  };
};
