import {RoomType} from '../../../../types/shared';

export enum StreamingPlatformType {
  PUREWEB = 'pureweb',
  UNSET = 'unset',
}

export type StreamingPlatformState = {
  copyLink: {
    url?: string;
  };
  gameReady: boolean;
  loading: {
    type: RoomType;
    phase: LoadingPhase;
    endedAt: Date | null;
    errorMessage: string | null;
    progress: number;
    startedAt: Date | null;
  };
  platform: {
    type: StreamingPlatformType;
    id: string;
  };
  streaming: boolean;
  streamingPlatformLoaded: boolean;
  runtimeErrorMessage: string | null;
  sessionTimedOut: Date | null;
};

export enum LoadingPhase {
  NotStarted = 1,
  Queued,
  Provisioning,
  RoomLoading,
  Ended,
}

export type GameAnalyticsEvent = {
  id: string;
  event: string;
  context?: Record<string, string | number>;
};

export const enum GameMessageType {
  AnalyticsEvent = 'PendoEvent',
  AppReadyToReceive = 'AppReadyToReceive',
  CopyShareLink = 'CopyShareLink',
  EngineError = 'EngineError',
  LoadingError = 'LoadingError',
  OpenVenueMapper = 'OpenVenueMapper',
  RoomLoadingComplete = 'RoomLoadingComplete',
  RoomLoadingInProgress = 'RoomLoadingInProgress',
  WLRFPButtonClicked = 'WLRFPButtonClicked',
}

export const enum ClientActionType {
  AppNotReady = 'AppNotReady',
  QueueStarted = 'QueueStarted',
  SessionTimeout = 'SessionTimeout',
  SetStreaming = 'SetStreaming',
  SetStreamingPlatformLoaded = 'SetStreamingPlatformLoaded',
  StartLoading = 'StartLoading',
  UpdateProvisioningProgress = 'UpdateProvisioningProgress',
}

export interface AnyPlatformAction {
  type: GameMessageType | ClientActionType;
  payload?: unknown;
}

export type MessageReducer<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  S = any,
  A extends AnyPlatformAction = AnyPlatformAction
> = (state: S, action: A) => S;
