import {useEffect} from 'react';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {
  GameMessageType,
  useStreamingPlatform,
} from '../../../helpers/context/streaming-platform-state';
import {copyShareLinkEvent, trackEvent} from '../../../lib/analytics';
import {logger} from '../../../lib/datadog';

/**
 * useCopyShareLinkEffect registers a listener for copying text when requested by the game
 */
export function useCopyShareLinkEffect(): void {
  const {messageEmitter} = useStreamingPlatform();
  const {propertyId, propertyName, roomId, roomName, roomType} =
    useRoomMetadataFlags();

  useEffect(() => {
    const link = window.location.href;
    const handler = async () => {
      if (!link) {
        logger.error('Share link is unavailable to copy.');
        return;
      }

      try {
        await navigator.clipboard.writeText(link);
        trackEvent(
          copyShareLinkEvent({
            'social_tables.pr3d.copy_link': link,
            'social_tables.property.id': propertyId,
            'social_tables.property.name': propertyName,
            'social_tables.room.id': roomId,
            'social_tables.room.name': roomName,
            'social_tables.room.type': roomType,
          })
        );
      } catch (error) {
        logger.error('Share Link copy failed: ', {error});
      }
    };
    messageEmitter.on(GameMessageType.CopyShareLink, handler);
    return () => {
      messageEmitter.off(GameMessageType.CopyShareLink, handler);
    };
  }, [messageEmitter, propertyId, propertyName, roomId, roomName, roomType]);
}
