import {useCallback, useEffect} from 'react';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {
  exceededMaxSessionDurationEvent,
  trackEvent,
} from '../../../lib/analytics';
import {fromMinutes} from '../../../lib/time';

type Params = {
  minutes: number;
  sessionStarted: boolean;
  onSessionLengthExceeded: () => void;
};

export function useMaxSessionLength({
  minutes,
  sessionStarted: loadingComplete,
  onSessionLengthExceeded,
}: Params): void {
  const {propertyId, propertyName, roomId, roomName, roomType} =
    useRoomMetadataFlags();
  const onComplete = useCallback(() => {
    trackEvent(
      exceededMaxSessionDurationEvent({
        'social_tables.pr3d.max_session_duration': fromMinutes(minutes),
        'social_tables.property.id': propertyId,
        'social_tables.property.name': propertyName,
        'social_tables.room.id': roomId,
        'social_tables.room.name': roomName,
        'social_tables.room.type': roomType,
      })
    );
    onSessionLengthExceeded();
  }, [
    minutes,
    onSessionLengthExceeded,
    propertyId,
    propertyName,
    roomId,
    roomName,
    roomType,
  ]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (loadingComplete) {
      timeoutId = setTimeout(onComplete, fromMinutes(minutes));
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [minutes, loadingComplete, onComplete]);
}
