import {allEnv} from 'next-runtime-env';
import {includes, reject, __} from 'ramda';
import {useCallback, useEffect, useState} from 'react';
import {useIdleTimer, DEFAULT_EVENTS} from 'react-idle-timer';
import {useRoomMetadataFlags} from '../../../helpers/context/room-metadata-context';
import {LoadingPhase} from '../../../helpers/context/streaming-platform-state';
import {idleSessionEvent, trackEvent} from '../../../lib/analytics';
import {logger} from '../../../lib/datadog';
import {fromSeconds} from '../../../lib/time';

const publicRuntimeConfig = allEnv<IPublicEnv>();
const IDLE_WARNING_SECONDS = parseInt(
  publicRuntimeConfig.NEXT_PUBLIC_IDLE_WARNING_SECONDS,
  10
);
const IDLE_EXIT_SECONDS = parseInt(
  publicRuntimeConfig.NEXT_PUBLIC_IDLE_EXIT_SECONDS,
  10
);

const DISALLOWED_EVENTS = ['visibilitychange'];
const ACTIVITY_EVENTS = reject(includes(__, DISALLOWED_EVENTS))(DEFAULT_EVENTS);

type Options = {
  onTimeout: () => void;
  loadingPhase: LoadingPhase;
};

export function useIdleTimeout({onTimeout, loadingPhase}: Options): void {
  const {propertyId, propertyName, roomId, roomName, roomType} =
    useRoomMetadataFlags();
  const [sessionStart, setSessionStart] = useState(new Date());
  const onIdle = useCallback(() => {
    trackEvent(
      idleSessionEvent({
        'social_tables.pr3d.total_session_duration':
          Date.now() - sessionStart.getTime(),
        'social_tables.property.id': propertyId,
        'social_tables.property.name': propertyName,
        'social_tables.room.id': roomId,
        'social_tables.room.name': roomName,
        'social_tables.room.type': roomType,
      })
    );
    onTimeout();
  }, [
    onTimeout,
    propertyId,
    propertyName,
    roomId,
    roomName,
    roomType,
    sessionStart,
  ]);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {start} = useIdleTimer({
    timeout: fromSeconds(IDLE_EXIT_SECONDS),
    promptTimeout: fromSeconds(IDLE_WARNING_SECONDS),
    onIdle,
    onPrompt: () => logger.debug('Timeout warning'),
    startManually: true,
    stopOnIdle: true,
    events: ACTIVITY_EVENTS,
  });

  useEffect(() => {
    if (loadingPhase === LoadingPhase.Ended) {
      logger.debug('Idle timer has begun');
      setSessionStart(new Date());
      start();
    }
  }, [loadingPhase, start]);
}
