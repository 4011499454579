import {StreamingPlatformState, MessageReducer} from '../../types/shared';
import {SetStreamingPlatformLoadedAction} from '../../types/client-actions';

export const setStreamingPlatformLoaded: MessageReducer<
  StreamingPlatformState,
  SetStreamingPlatformLoadedAction
> = (state: StreamingPlatformState, {payload}) => ({
  ...state,
  streamingPlatformLoaded: payload.isLoaded,
  platform: payload.platform,
});
