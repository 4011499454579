import {AnalyticsEventType, QueueFinishedEvent} from '../types';

export function queueFinishedEvent(
  context: QueueFinishedEvent['context']
): QueueFinishedEvent {
  return {
    event: AnalyticsEventType.QueueFinished,
    context,
  };
}
