import {
  StreamingPlatformState,
  MessageReducer,
  LoadingPhase,
} from '../../types/shared';
import {RoomLoadingInProgressMessage} from '../../types/game-messages';
import {calculatePhaseProgress} from '../../../../../lib/loading-progress-calculator';

export const roomLoadingInProgress: MessageReducer<
  StreamingPlatformState,
  RoomLoadingInProgressMessage
> = (state, {payload: {Progress}}): StreamingPlatformState => ({
  ...state,
  loading: {
    ...state.loading,
    phase: LoadingPhase.RoomLoading,
    progress: calculatePhaseProgress(
      LoadingPhase.RoomLoading,
      Progress,
      state.loading.type
    ),
  },
});
