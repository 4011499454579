import {ReactNode, JSX} from 'react';
import Head from 'next/head';
import {
  FeatureFlagsProviderProps,
  withFeatureFlagsProvider,
} from '../../helpers/context/feature-flags-context';
import {
  RoomMetadataProviderProps,
  withRoomMetadataProvider,
} from '../../helpers/context/room-metadata-context';
import styles from './Layout.module.css';

type Props = FeatureFlagsProviderProps &
  RoomMetadataProviderProps & {
    children?: ReactNode;
    title?: string;
  };

const PAGE_BASE_TITLE = '3D | Social Tables | Cvent';

export const formatTitle = (prefix?: string): string =>
  prefix ? `${prefix} ${PAGE_BASE_TITLE}` : `${PAGE_BASE_TITLE}`;

const Component = ({children, title}: Readonly<Props>): JSX.Element => (
  <div>
    <Head>
      <title>{formatTitle(title)}</title>
      <link rel="icon" href="/cvent_favicon_16x16.png" type="image/x-icon" />
    </Head>
    <main className={styles.container}>{children}</main>
  </div>
);

export const Layout = withRoomMetadataProvider<Props>(
  withFeatureFlagsProvider<Props>(Component)
);
