import {JSX} from 'react';
import {useRoomMetadataFlags} from '../../helpers/context/room-metadata-context';
import {Provider as StreamingPlatformProvider} from '../../helpers/context/streaming-platform-state';
import {GamePlayer as Component, Props} from './component';

export type PlayerProps = Props;

export function GamePlayer(props: Readonly<Props>): JSX.Element {
  const {roomType} = useRoomMetadataFlags();

  return (
    <StreamingPlatformProvider loadingType={roomType}>
      <Component {...props} />
    </StreamingPlatformProvider>
  );
}
