import dynamic from 'next/dynamic';
import {Fragment} from 'react';
import {ErrorPage} from '../../common/components/ErrorPage';
import {logger} from '../../lib/datadog';
import type {PlayerProps} from '../GamePlayer';

export const PureWebPlayer = dynamic<PlayerProps>(
  async () => {
    const {PureWebPlayer: Component} = await import('./component');

    return Component;
  },
  {
    ssr: false,
    loading: ({error}) => {
      if (error) {
        logger.error('Failed to load PureWeb player', {
          error,
          stack: error.stack,
        });
      }
      return error ? <ErrorPage>{error.message}</ErrorPage> : <Fragment />;
    },
  }
);
