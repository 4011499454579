import {Fragment, useCallback, useEffect, useRef, JSX} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {allEnv} from 'next-runtime-env';
import {
  LoadingPhase,
  sessionTimeoutAction,
  useStreamingPlatform,
} from '../../helpers/context/streaming-platform-state';
import {useAutoplay} from '../../helpers/hooks/use-autoplay';
import {AnyGameAction} from '../../lib/game-actions';
import {PureWebPlayer} from '../PureWebPlayer';
import {SessionTimeoutPage} from '../SessionTimeoutPage/component';
import {useStreamingSessionControl} from './helpers/use-streaming-session-control';
import type {PlayerProps} from './';
import styles from './GamePlayer.module.css';
import {
  StreamingPlatform,
  useAnalyticsEventEffect,
  useCopyShareLinkEffect,
  useErrorLoggingEffect,
  useIdleTimeout,
  useLoadingPhaseEffect,
  useMaxSessionLength,
  useOpenDiagramEffect,
  useSelectStreamingPlatform,
} from './helpers';
import {LoadingOverlay} from './LoadingOverlay';
import {useWhiteLabelRfpClickEffect} from './helpers/use-whitelabel-rfp-click-effect';

const publicRuntimeConfig = allEnv<IPublicEnv>();

export type Props = {
  onReadyAction?: AnyGameAction;
  loadingImage?: string | null;
  propertyName: string;
  bookableRoomName: string;
  diagramId?: string;
  referrer?: string;
  showWhitelabelRFP?: boolean;
  useAutomaticFailover: boolean;
  showEventPlannerButton?: boolean;
};

const STREAMING_PLATFORM: Record<
  StreamingPlatform,
  React.ComponentType<PlayerProps>
> = {
  pureweb: PureWebPlayer,
} as const;

export function GamePlayer(props: Readonly<Props>): JSX.Element {
  const {
    bookableRoomName: roomName,
    diagramId,
    loadingImage,
    propertyName,
    referrer,
    showWhitelabelRFP,
  } = props;
  const streamingPlatform = useSelectStreamingPlatform();
  const PlatformPlayer = STREAMING_PLATFORM[streamingPlatform];
  const {
    state: {loading, runtimeErrorMessage, sessionTimedOut},
    dispatch,
  } = useStreamingPlatform();
  const [hasControl, wrestControl] = useStreamingSessionControl();
  const autoplayRef = useRef(null);
  const [beginPlay, setBeginPlay] = useAutoplay();
  const handleEnterRoom = useCallback(() => setBeginPlay(true), [setBeginPlay]);
  const handleSessionTimeout = useCallback(
    () => dispatch(sessionTimeoutAction()),
    [dispatch]
  );

  const authorizedToPlay = beginPlay && hasControl;
  const loadingComplete =
    loading.phase === LoadingPhase.Ended &&
    !runtimeErrorMessage &&
    !loading.errorMessage;

  useLoadingPhaseEffect({onLoadStart: wrestControl, referrer});
  useAnalyticsEventEffect();
  useCopyShareLinkEffect();
  useOpenDiagramEffect({diagramId});
  useMaxSessionLength({
    minutes: parseInt(publicRuntimeConfig.NEXT_PUBLIC_MAX_SESSION_MINUTES, 10),
    sessionStarted: loadingComplete,
    onSessionLengthExceeded: handleSessionTimeout,
  });
  useIdleTimeout({
    onTimeout: handleSessionTimeout,
    loadingPhase: loading.phase,
  });
  useErrorLoggingEffect({
    runtimeError: runtimeErrorMessage,
    loadingError: loading.errorMessage,
  });
  useWhiteLabelRfpClickEffect(showWhitelabelRFP);

  // Take control when autoplaying on load.
  useEffect(() => {
    if (beginPlay) {
      wrestControl();
    }
  }, [beginPlay, wrestControl]);

  // Timeout session when control has been lost to another tab.
  useEffect(() => {
    if (!hasControl && loading.phase !== LoadingPhase.NotStarted) {
      handleSessionTimeout();
    }
  }, [handleSessionTimeout, hasControl, loading.phase, wrestControl]);

  if (sessionTimedOut) {
    return (
      <SessionTimeoutPage
        backgroundImage={loadingImage}
        propertyName={propertyName}
        bookableRoomName={roomName}
      />
    );
  }

  return (
    <TransitionGroup className={styles.fillParentElement}>
      {loading.phase === LoadingPhase.Ended ? null : (
        <CSSTransition
          nodeRef={autoplayRef}
          in={true}
          timeout={1000}
          classNames={{
            exitActive: styles.autoplayExitActive,
          }}
          unmountOnExit
        >
          <LoadingOverlay
            ref={autoplayRef}
            onEnterRoom={handleEnterRoom}
            loadingImage={loadingImage}
            propertyName={propertyName}
            bookableRoomName={roomName}
            autoplayAllowed={authorizedToPlay}
          />
        </CSSTransition>
      )}
      {authorizedToPlay ? <PlatformPlayer {...props} /> : <Fragment />}
    </TransitionGroup>
  );
}
