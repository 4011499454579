import {StreamingPlatformState, MessageReducer} from '../../types/shared';
import {AppNotReadyAction} from '../../types/client-actions';

export const appNotReady: MessageReducer<
  StreamingPlatformState,
  AppNotReadyAction
> = (state: StreamingPlatformState): StreamingPlatformState => ({
  ...state,
  gameReady: false,
});
