import {SessionTimeoutAction} from '../../types/client-actions';
import {MessageReducer, StreamingPlatformState} from '../../types/shared';

export const sessionTimeout: MessageReducer<
  StreamingPlatformState,
  SessionTimeoutAction
> = (state: StreamingPlatformState): StreamingPlatformState => ({
  ...state,
  sessionTimedOut: new Date(),
});
