import {compose, replace} from 'ramda';
import {camelCase} from '../../../camel-case';
import {Context} from '../../types';

const transformPropertyName = compose(
  camelCase,
  replace(/[-*]/g, '_'),
  replace('social_tables.', ''),
  replace('pr3d.', '')
);

export function transformProperties<T extends Context>(context: T): T {
  const keys = Object.keys(context);
  const newContext: Context = {};

  for (const key of keys) {
    newContext[transformPropertyName(key)] = context[key];
  }

  return newContext as T;
}
