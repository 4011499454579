import {ProgressBar, ProgressBarProps} from 'react-aria-components';
import styles from './IndeterminateLoader.module.scss';

interface IndeterminateLoaderProps extends ProgressBarProps {
  color?: string;
}

export default function IndeterminateLoader({
  color = '#FFF',
  ...props
}: Readonly<IndeterminateLoaderProps>) {
  return (
    <ProgressBar
      className={styles['progress-bar']}
      {...props}
      isIndeterminate={true}
      aria-label="loading stream"
    >
      <div className={styles.bar} style={{borderColor: color}}>
        <span
          className={styles.fill}
          role="progressbar"
          style={{backgroundColor: color}}
        />
      </div>
    </ProgressBar>
  );
}
